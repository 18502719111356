<template>
  <div>
    {{ artikelcode }}
    <p>{{ artikelDetails.bezeichnung }}</p>
  </div>
</template>

<script>
export default {
  name: "ArtikelDetailComponent",
  props: {
    artikelcode: {
      type: String
    }
  },
  computed: {
    artikelDetails() {
      return this.$store.getters.getProductById(this.artikelcode);
    }
  }
};
</script>

<style></style>
