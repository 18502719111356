<template>
  <div>
    <h2>Artikel</h2>

    <artikel :artikelcode="$route.params.id" />
  </div>
</template>

<script>
import Artikel from "../../components/Artikel.vue";

export default {
  name: "ArtikelDetailAnsicht",
  components: {
    Artikel
  }
};
</script>
